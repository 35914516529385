import React from 'react';
import styled from 'styled-components'
import ContinueArrow from '../../assets/continue-arrow@2x.png'

const FWContinueButton = styled.button.attrs(
)`
    height: 55px;
    width: 375px;
    margin-top: 30px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #4D4F53;
    color: #fff;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    line-height: 22px;
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ContinueNavButton = styled(FWContinueButton)`
    width: 265px;
    left: auto;
    right: 0;
    margin-top: 0;
`

const BackNavButton = styled(FWContinueButton)`
    margin-top: 0;
    width: 110px;
    background-color: #7C7C7C;
`

const ArrowIcon = styled.img.attrs({
    src: ContinueArrow
})`
    height: 16px;
    margin-left: 14px;
`

export const BackButton = ({text}) => {
    return (
        <BackNavButton>
            {text}
        </BackNavButton>
    )
}

export const ContinueButton = ({text}) => {
    return (
        <ContinueNavButton>
            {text}
            <ArrowIcon />
        </ContinueNavButton>
    )
}

export const WideContinueButton = (props) => {
    return (
        <FWContinueButton>
            {props.text}
            <ArrowIcon />
        </FWContinueButton>
    )
}

export default ContinueButton;