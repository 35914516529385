// import { auth } from '../../service/firebase'
// import firebase from 'firebase'

export const inputChange = (name, value) => {
    return {
      type: `LOGIN_${name.toUpperCase()}_CHANGE`,
      value
    }
  }

  //----------------------------- Create User Actions ---------------------//

  export const signUpUser = (email, password) => async (dispatch) => {
    // auth.createUserWithEmailAndPassword(email, password)
    //   .then((user) => {
    //     dispatch(signUpUserSuccess(user))
    //   }).catch((error) => {
    //     dispatch(signUpUserError(error))
    //   })

      // .then(response => {
      //   console.log(response)
      //   const email = response.user.email
      //   const userID = response.user.uid
      //   return ({ email, userID })
      // })
      // .catch(function (error) {
      //   console.log(`Failed w/ error: ${error.message}`)
      //   return error.message
      // })
  }

  export const signUpUserSuccess = () => {
    return {
      type: 'SIGN_UP_SUCCESS'
    }
  }

  export const signUpUserError = (error) => {
    return {
      type: 'SIGN_UP_ERROR',
      error
    }
  }

//----------------------------- Login Actions ------------------------//

export const loginSuccess = (user) => {
    console.log("LOGIN SUCCESS")
    return {
      type: 'LOGIN_SUCCESS',
      user
    }
  }

  export const loginError = () => {
    console.log("LOGIN ERROR")
    return {
      type: 'LOGIN_ERROR'
    }
  }

  export const submitLogin = (email, password) => (dispatch ) => {
    // auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    //   .then(function() {
    //     auth.signInWithEmailAndPassword(email, password).then((user) => {
    //       dispatch(loginSuccess(user))
    //     }).catch(() => {
    //       dispatch(loginError())
    //     })
    //   })

      //     .then(response => {
      //       console.log(response);
      //       const userID = response.user.uid
      //       return { userID }
      //     }).catch(err => {
      //     console.log(`Failed w/ error: ${err}`)
      //     return err
      //   })
      // }
  }

  export const signOutUser = (user) => (dispatch) => {
    // auth.signOut().then(()=> {
    //   dispatch(signOutSuccess())
    // })
  }

  export const signOutSuccess = () => {
    return {
      type: 'SIGN_OUT_SUCCESS'
    }
  }

//----------------------------- Login Reducer ------------------------//


export const loginReducer = (state = { email: '', password: '', loggedIn: false, loading: false, error: '' },
  action) => {
  switch (action.type) {
//   case 'CHECK_USER':
//     if (isAuthenticated()) {
//       return {
//         ...state,
//         loggedIn: true,
//         email: auth.currentUser.email,
//         uid: auth.currentUser.uid
//       }
//     } else {
//       return state
//     }
  case 'LOGIN_EMAIL_CHANGE' :
    return {
      ...state,
      email: action.value
    }
  case 'LOGIN_PASSWORD_CHANGE' :
    return {
      ...state,
      password: action.value
    };
  case 'LOGIN_SUCCESS' :
    return {
      ...state,
      loggedIn: true,
      email: action.user.user.email,
      password: null,
      uid: action.user.user.uid
    }
  case 'LOGIN_ERROR' :
    return {
      ...state,
      error: 'Failed to login'
    }
  case 'SIGN_UP_ERROR':
    return {
      ...state,
      error: 'Invalid email'
    }
  case 'SIGN_OUT_SUCCESS':
    return {
      ...state,
      loggedIn: false,
      email: null,
      password: null,
      uid: null
    }
  default:
    return state;
  }
}

export default loginReducer;
