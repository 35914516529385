import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Provider } from 'react-redux'
import { withRouter } from 'react-router-dom'

import configureStore from './configureStore'

class ProviderWithRouter extends React.Component {
  store = configureStore(this.props.history)

  static propTypes = {
    children: PropTypes.node.isRequired,
    history: ReactRouterPropTypes.history.isRequired
  }

  render() {
    return (
      <Provider store={this.store}>
        {this.props.children}
      </Provider>
    )
  }
}

export default withRouter(ProviderWithRouter)
