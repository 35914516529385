import React from 'react'
import { withRouter } from 'react-router-dom'
import { ReactTypeformEmbed } from 'react-typeform-embed'
import * as ROUTES from '../../constants/routes'

class FormView extends React.Component {
  handleOnSubmit = () => {
    // Two hrs worth of debugging and it all comes down to one line.
    // Remove the Typeform embed class from the body because
    // it prevents scrolling until you refresh the page.
    document.body.classList.remove('__typeform-embed-mobile-modal-open')
    this.props.history.push(ROUTES.WEIGH_IN_INITIAL)
  }

  render () {
    return (
      <div>
        <ReactTypeformEmbed
          popup={false}
          onSubmit={this.handleOnSubmit}
          url='https://wholebodychallenge.typeform.com/to/vMSlqh' />
      </div>
    )
  }
}

export default withRouter(FormView)
