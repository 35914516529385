import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { withFirebase } from '../../services/Firebase'

import { Button, PhotoCapture, WordOfTheDay } from '../../components'

class WelcomeView extends React.Component {
  state = {
    wotd: null,
    error: null,
    imageSrc: null,
    user: null
  }

  componentDidMount () {
    this.props.firebase.getWordOfTheDay()
      .then((wotd) => {
        this.setState({ wotd });
      })
      .catch(error => {
        this.setState({ error });
      })

    this.props.firebase.getUserDataByEmail('')
      .then(response => {
        this.setState({ user: response })
      })
      .catch(error => {
        console.error(error)
      })
  }

  setCapturedImage = ({ imageSrc }) => {
    console.log('CLICK', { imageSrc })
    this.setState({ imageSrc })
  }

  render () {
    console.log('USER', { user: this.state.user })

    return (
      <Container maxWidth="sm">
        <div>
          <Typography variant="h4" component="h1" gutterBottom>
            Welcome View
          </Typography>

          <Button variant='contained' color='primary'>Primary Button</Button>
          <Button rounded variant='contained' color='secondary'>Primary Button</Button>
          <Button rounded variant='outlined' color='secondary'>Outlined Secondary</Button>

          <Typography>
            <Link to='register'>Join Now</Link>
            <Link to='login'>Log In</Link>
          </Typography>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '25px',
            paddingTop: '25px'
          }}>
            <PhotoCapture
              onCapture={this.setCapturedImage}
              imageSrc={this.state.imageSrc}
            />
          </div>

          <WordOfTheDay word={this.state.wotd} />

          { this.state.error && (
            <div>
              Error: {this.state.error}
            </div>
          )}

        </div>
      </Container>
    )
  }
}

export default withFirebase(WelcomeView)
