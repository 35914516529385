import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'
import app from 'firebase/app'
import moment from 'moment'

import MD5 from 'md5.js'

const config = {
  apiKey: "AIzaSyDmqHffeo8WVbWQ8piolA98r14qTiLCqFA",
  appId: "1:807860932828:web:50da420447b5b097532419",
  authDomain: "newyouweb-5edac.firebaseapp.com",
  databaseURL: "https://newyouweb-5edac.firebaseio.com",
  measurementId: "G-R46NWREN7W",
  messagingSenderId: "807860932828",
  projectId: "newyouweb-5edac",
  storageBucket: "newyouweb-5edac.appspot.com"
}

class Firebase {
  constructor () {
    app.initializeApp(config)
    this.auth = app.auth()
    this.db = app.database()
    this.storage = app.storage()
    this.functions = app.functions()
  }

  /**
   * @param {String} emailAddr
   * @returns {String}
   * @private
   */
  _generateUserIdHash = (emailAddr) => {
    if (typeof emailAddr === 'string' && emailAddr.includes('@')) {
      const hashable = emailAddr.toLowerCase()
      return new MD5().update(hashable).digest('hex')
    }
    throw new Error(`given value [${emailAddr}] cannot be used to generate User ID`)
  }

  /**
   * Weigh-in entry IDs are numeric timestamps.
   *
   * Should make them easy to order into chronological
   * order and determine the exact time and date when
   * the weigh in entry was created.
   *
   * @returns {string}
   * @private
   */
  _generateWeighInHash = () => {
    return Date.now().toString()
  }

  // Firebase Auth API

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()

  getCurrentUser = () => this.auth.currentUser

  getCurrentUserData = () => this.getUserDataByEmail(this.auth.currentUser.email)

  // Firebase Database Functions

  /**
   * @returns {Boolean}
   */
  checkAdmin = (emailAddr) => {
    let userId = this._generateUserIdHash(emailAddr)

    return this.db.ref(`/users/${userId}/isAdmin`).once('value', (snapshot) => {
       return snapshot.val()
    })
  }
  
  /**
   * @returns {Promise<String>}
   */
  getWordOfTheDay = () =>
    new Promise((resolve, reject) =>
      this.db.ref('/wotd-word').on(
        'value',
        snapshot => resolve(snapshot.val()),
        error => reject(error)))


  /**
   * Returns all user data.
   * @returns {Promise<{}>}
   */
  getAllUserData = () =>
  
    new Promise((resolve, reject) => {
        return this.db.ref('/users/').orderByChild('userId').on(
          'value',
          snapshot => resolve(snapshot.val()),
          error => reject(error))
  })

  /**
   * Returns user data record corresponding to email address.
   * @param {String} emailAddr
   * @returns {Promise<{}>}
   */
  getUserDataByEmail = (emailAddr) =>
    new Promise((resolve, reject) => {
      if (typeof emailAddr === 'string' && emailAddr.includes('@')) {
        const userId = this._generateUserIdHash(emailAddr)
        return this.db.ref(`/users/${userId}`).on(
          'value',
          snapshot => resolve(snapshot.val()),
          error => reject(error))
      }
      reject(`provided email string [${emailAddr}] does not appear to be an email address`)
    })

  /**
   * Returns all weigh in entries associated the given email address.
   *
   * @param emailAddr
   * @returns {Promise<[]>}
   */

    getUserEntriesByEmail = (emailAddr) =>
    new Promise((resolve, reject) => {
      if (typeof emailAddr === 'string' && emailAddr.includes('@')) {
        const userId = this._generateUserIdHash(emailAddr)
        return this.db.ref(`/users/${userId}/entries/`).on(
          'value',
          snapshot => resolve((snapshot.val())),
          error => reject(error))
      }
      reject(`provided email string [${emailAddr}] does not appear to be an email address`)
    })

  /**
   * Returns the last user weigh in entry associated with the given email address.
   *
   * @param emailAddr
   * @returns {Promise<{}|null>}
   */
  getLastUserEntry = (emailAddr) =>
    this.getUserEntriesByEmail(emailAddr)
      .then((entries) => {
        if (entries && Object.values(entries).length > 0) {
          const timestamps = Object.values(entries).map(it => it.dateCreated)
          const latestEntry = Math.max(...timestamps)
          return entries[latestEntry]
        }
        // no entries
        return null
      })

  /**
   * Returns true if the user is elligible for creating another weigh-in entry.
   *
   * @param emailAddr
   * @returns {Promise<boolean>}
   */
  canUserAddNewEntry = (emailAddr) =>
    this.getLastUserEntry(emailAddr)
      .then((entry) => {
        if (entry === null) {
          return true
        }

        const entryCreated = entry.dateCreated
        const weekStart = moment().startOf('week')
        return weekStart.isAfter(entryCreated)
      })

  /**
   * @param {String} authUserId
   * @param {String} email
   * @param {String} fName
   * @param {String} lName
   * @returns {Promise<unknown>}
   */
  createUserDataRecord = (authUserId, email, fName, lName, isAdmin) =>
    new Promise((resolve, reject) => {
      // get the user data record if exists
      this.getUserDataByEmail(email)
        .then((userData) => {
          const regDate = new Date().toISOString()
          const userId = this._generateUserIdHash(email)
          const record = {
            authUserId,
            userId,
            lName,
            fName,
            email,
            regDate,
            isAdmin
          }

          console.log('HASH', userId)

          // if userData already exists, combine. otherwise, write new
          const payload = (userData === null)
            ? { ...record, meta: { importedUser: false } }
            : { ...userData, ...record }
          this.db.ref(`/users/${userId}`).set(payload,
            (error) => error ? reject(error) : resolve(payload))
        })
        .catch((error) => reject(error))
    })

  /**
   *
   * @param {String} userId
   * @param {String} wotd
   * @param {Number} weight
   * @param {Object} photos
   * @param {String} photos.backImg
   * @param {String} photos.frontImg
   * @param {String} photos.scaleImg
   * @param {String} photos.sideImg
   * @param {String} photos.wordImg
   * @returns {Promise<{}>}
   */
  createWeighInEntry = (userId, wotd, weight, photos) =>
    new Promise((resolve, reject) => {
      const entryId = this._generateWeighInHash()
      const dateCreated = Date.now()

      const ref = this.db.ref(`/users/${userId}/entries/${entryId}`)
      const entry = { entryId, wotd, weight, dateCreated, photos: { } }
      const slug = wotd.toLowerCase().replace(/[^0-9a-z]/, '')
      const promises = [
        this._doUploadPhotoToStorage(userId, entryId, `${slug}-photo-back`, photos.backImg),
        this._doUploadPhotoToStorage(userId, entryId, `${slug}-photo-front`, photos.frontImg),
        this._doUploadPhotoToStorage(userId, entryId, `${slug}-photo-scale`, photos.scaleImg),
        this._doUploadPhotoToStorage(userId, entryId, `${slug}-photo-side`, photos.sideImg),
        this._doUploadPhotoToStorage(userId, entryId, `${slug}-photo-word`, photos.wordImg)
      ]

      Promise.all(promises)
        .then(([backUrl, frontUrl, scaleUrl, sideUrl, wordUrl]) => {
          entry.photos = { backUrl, frontUrl, scaleUrl, sideUrl, wordUrl }
          return ref.set(entry, (error) => (error) ? reject(error) : resolve(entry))
        })
        .catch((error) => reject(error))
    })

    

  // Firebase Storage Functions

  _doUploadPhotoToStorage = (userId, entryId, key, data) =>
    new Promise((resolve, reject) => {
      // TODO: the image path is URL encoded in the final download URL. How do we make it an actual path?
      const path = `/${userId}/entries/${entryId}/${key}`
      const ref = this.storage.ref().child(path)

      const uploadTask = ref.putString(data, 'data_url')
      uploadTask.on('state_changed', (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and
          // the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')

          // switch (snapshot.state) {
          //   case app.TaskState.PAUSED: // or 'paused'
          //     console.log('Upload is paused')
          //     break
          //
          //   case app.TaskState.RUNNING: // or 'running'
          //     console.log('Upload is running')
          //     break
          // }
        },
        (error) => reject(error),
        () => {
          uploadTask.snapshot.ref.getDownloadURL()
            .then((downloadUrl) => resolve(downloadUrl))
        })
    })
}

export default Firebase
