import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background: #ff6d22;
  padding: 8px 8px 24px 8px;
  position: relative;
  width: 310px;
  margin: 0 auto;
  :after {
    background: linear-gradient(-35deg, #f9f9f9 10px, transparent 0), linear-gradient(35deg, #f9f9f9 10px, transparent 0);
    background-position: center;
    background-repeat: repeat-x;
    background-size: 32px 32px;
    content: " ";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 310px;
    height: 32px;
  }
`

const Word = styled.div`
  color: #ffffff;
  font-family: 'DSDigital',sans-serif;
  font-size: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`

export const QuestionLink = styled.p`
  margin: 0 auto 20px;
  width: 200px;
  font-family: 'Open Sans', sans-serif;
  color: #008EC6;
  font-size: 16px;
  font-weight: 600;
`

export const WrittenWord = styled(Word)`
  font-family: 'Permanent Marker', cursive;
  color: #4D4F53;
  font-size: 50px;
  background-color: #fff;
  height: 160px;
  border: 1px dashed #4D4F53;
  border-radius: 5px;
  margin: 25px auto;
`

export const WordOfTheDay = ({ text }) => (
  <Container>
    <Word>
      {text}
    </Word>
  </Container>
)

// export default WordOfTheDay
