import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import AuthUserContext, { AuthUserDataContext } from './context'
import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount () {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!(condition(authUser))) {
            this.props.history.push(ROUTES.SIGNUP)
          }
        }
      )
    }

    componentWillUnmount () {
      this.listener()
    }

    render () {
      return (
        <AuthUserContext.Consumer>
          {authUser => (
            <AuthUserDataContext.Consumer>
              {authUserData => (
                condition(authUser) ? <Component {...this.props} authUser={authUser} authUserData={authUserData} /> : null
              )}
            </AuthUserDataContext.Consumer>
            )
          }
        </AuthUserContext.Consumer>
      )
    }
  }

  return compose(
    withRouter,
    withFirebase
  )(WithAuthorization)
}

export default withAuthorization
