import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import ReduxThunk from 'redux-thunk'

import rootReducer from '../../redux/reducers'

const isProd = process.env.NODE_ENV === 'production'

export default (preloadedState) => {
  const middlewares = [ReduxThunk]
  const middlewareEnhancer = applyMiddleware(...middlewares)
  const enhancers = [middlewareEnhancer]

  const composer = isProd ? compose : composeWithDevTools
  const composedEnhancers = composer(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)
  return store
}
