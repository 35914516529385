import React, { Component } from 'react'
import Chart from "react-apexcharts"
import MobileWrapper from '../MobileWrapper'
import styled from 'styled-components'
import { Divider } from '@material-ui/core'

const DashboardWrapper = styled(MobileWrapper)`
  margin: 0 auto;
`

const StyledDivider = styled(Divider)`
    background-color: #DBDBDB;
    width: 100vw;
`

const WeightChangeContainer = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px;
`

const WeightChangeNumber = styled.h3`
  height: 15px;
  font-size: 12px;
  margin: 5px;
  font-weight: 600;
`

const WeightChangeTitle = styled(WeightChangeNumber)`
  font-size: 10px;
  font-weight: 400;
`

const WeightLossChart = ({weightData, startWeight, currentWeight }) => {

  const yData = weightData.map( entry => entry.weight)
  const xData = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6', 'Week 7', 'Week 8']

  const weightDifference = startWeight - currentWeight
  const weightChange = startWeight/currentWeight 

  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false
      }
    },
    markers: {
      size: 5,
      colors: 'orange',
      strokeColors: 'orange'
    },
    // dataLabels: {
    //   enabled: true,
    //   colors: 'orange'
    // },
    xaxis: {
      categories: xData
    },
    yaxis: {
      forceNiceScale: true,
      tickAmount: 6,
      min: currentWeight - 2,
      max: startWeight + 2
    }
  }

  const series = [
    {
      name: "Weight",
      data: yData
    }
  ]

  return (
    <DashboardWrapper>
      <WeightChangeContainer>
        <div>
          <WeightChangeNumber>
            {startWeight} lbs
          </WeightChangeNumber>
          <WeightChangeTitle>
            START
          </WeightChangeTitle>
        </div>
        <div>
          <WeightChangeNumber>
            {currentWeight} lbs
          </WeightChangeNumber>
          <WeightChangeTitle>
            CURRENT
          </WeightChangeTitle>
        </div>
        <div>
          <WeightChangeNumber>
            {weightDifference} lbs
          </WeightChangeNumber>
          <WeightChangeTitle>
            CHANGE ({Math.round((weightChange + Number.EPSILON) * 100) / 100}%)
          </WeightChangeTitle>
        </div>
      </WeightChangeContainer>
      <StyledDivider />
      <div className="row">
        <div className="line-chart">
          <Chart
            options={options}
            series={series}
            type="line"
            width= "350"
          />
        </div>
      </div>
    </DashboardWrapper>
  );
}


export default WeightLossChart