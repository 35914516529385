import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import MatDrawer from '@material-ui/core/Drawer'
import { spacing } from '@material-ui/system'

import SignOutButton from '../SignOutButton'
import { AuthUserContext } from '../../services/Session'
import * as ROUTES from '../../constants/routes'

// TODO: should be a configurable value on the theme.
const drawerWidth = 240

const Drawer = styled(MatDrawer).attrs({
  anchor: 'left',
  variant: 'persistent',
  elevation: 0
})`
  flex-shrink: 0;
  width: ${drawerWidth};
`

const DrawerHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  ${spacing}
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

const NavigationAuth = ({ authUser }) => (
  <List>
    <ListItem>
      <StyledLink to={ROUTES.ACCOUNT}>My Account</StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink to={ROUTES.ACCOUNT_WEIGH_IN}>New Weigh-in</StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink to={ROUTES.ENTRY_PHOTOS}>Review Photos</StyledLink>
    </ListItem>
    {!!authUser.isAdmin && (
      <ListItem>
        <StyledLink to={ROUTES.ADMIN}>Admin</StyledLink>
      </ListItem>
    )}
    <ListItem>
      <SignOutButton />
    </ListItem>
  </List>
)

const NavigationAnon = () => (
  <List>
    <ListItem>
      <StyledLink to={ROUTES.DEFAULT}>Home</StyledLink>
    </ListItem>
    <ListItem>
      <StyledLink to={ROUTES.LOGIN}>Log In</StyledLink>
    </ListItem>
  </List>
)

const ComposedDrawer = ({ open, handleDrawerClose }) => (
  <Drawer open={open}>
    <DrawerHeader>
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon />
      </IconButton>
    </DrawerHeader>
    <Divider />
    <AuthUserContext.Consumer>
      {
        authUser =>
          authUser
            ? <NavigationAuth authUser={authUser} />
            : <NavigationAnon />
      }
    </AuthUserContext.Consumer>
  </Drawer>
)

ComposedDrawer.defaultProps = {
  open: false
}

ComposedDrawer.propTypes = {
  open: PropTypes.bool
}

export default ComposedDrawer
