import { createMuiTheme } from "@material-ui/core/styles"

export const overridings = {
  name: 'NewU Theme',
  palette: {
    primary: {
      main: '#0083be',
      contrastText: '#fafafa'
    },
    secondary: {
      main: '#ff6d22',
      contrastText: '#fafafa'
    }
  },
  typography: {
    h1: {
      color: '#0083BE'
    },
    h2: {
      color: '#484848'
    },
    body1: {
      fontSize: 15,
      color: '#56585C',
      fontFamily: 'Source Sans Pro'
    },
    body2: {
      fontSize: 12,
      color: '#95989A'
    },
    button: {
      textTransform: 'none'
    }
  }
}

export default createMuiTheme(overridings)
