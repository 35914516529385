import React from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import { StylesProvider } from '@material-ui/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

import NewUTheme from '../../theme/newu-theme'
import Layout from '../Layout'

const StyledApp = () => (
  <StylesProvider injectFirst>
    <CssBaseline />
    <StyledThemeProvider theme={NewUTheme}>
      <ThemeProvider theme={NewUTheme}>
        <Layout />
      </ThemeProvider>
    </StyledThemeProvider>
  </StylesProvider>
)

export default StyledApp
