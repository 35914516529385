import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ProgressBar from './ProgressBar'

const Container = styled.div`
  position: relative;
  top: 0;
  height: 150px;
  width: 100vw;
  background-color: #4D4F53;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledIcon = styled.img`
  height: 40px;
  width: auto;
  margin: 0 auto 5px;
`
const StyledTitle = styled.h3`
  color: #fff;
  font-size: 12px;
  height: 15px;
  text-transform: uppercase;
`

const PhotoHeader = ({ showProgress, currentStep, totalSteps, icon, title}) => (
  <Container>
    <StyledIcon src={icon} alt='scale-icon' />
    <StyledTitle>
      {title}
    </StyledTitle>
    { showProgress && (
      <ProgressBar
        currentStep={currentStep}
        totalSteps={totalSteps}
      />
    )}
  </Container>
)

PhotoHeader.defaultProps = {
  showProgress: false
}
PhotoHeader.propTypes = {
  showProgress: PropTypes.bool
}

export default PhotoHeader
