import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button, Navigation, MobileWrapper, HeaderTitle, BackButton } from '../../components'
import { withFirebase } from '../../services/Firebase'

const HeaderSubtitle = styled(Typography)`
  margin-top: 5px;
  text-align: center;
`

const BackToLogIn = styled(Button)`
  align-self: flex-end;
`

const ValidationError = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: .25rem;
`

const SuccessMessage = styled.div`
  color: #155724;
  background-color: #d4edda;
  text-align: center;
  /* position: relative; */
  padding: .75rem;
  margin-bottom: 1rem;
  border: 1px solid #c3e6cb;
  border-radius: .25rem;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`

const LoginLink = styled(Link)`
  text-decoration: none;
  color: #FF6D22;
`

const SuccessLoginLink = styled(LoginLink)`
  color: #fff;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const SuccessMessageBody = styled.p`
  margin-top: 20px;
`

const StyledUserEmail = styled.span`
  font-weight: bold;
  margin: 0 5px;
`

const INITIAL_STATE = {
  email: '',
  isLoading: false,
  resetSent: false,
  error: null
}

class PasswordView extends React.Component {
  state = {
    ...INITIAL_STATE
  }

  handleOnChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleOnSubmit = (event) => {
    this.setState({ isLoading: true })
    const { email } = this.state
    this.props.firebase.doPasswordReset(email)
      .then(() => {
        this.setState({ resetSent: true })
      })
      .catch((error) => {
        this.setState({ error, isLoading: false })
      })

    event.preventDefault()
  }

  render() {
    const { email, isLoading, resetSent, error } = this.state
    const isInvalid = email === ''
    return (
      <MobileWrapper>
        <Navigation />

        { resetSent && (
          <div>
            <SuccessMessage>
              <HeaderTitle>Password Reset Sent!</HeaderTitle>
              <SuccessMessageBody>
                You will receive a message at <StyledUserEmail>{email}</StyledUserEmail>
                if you’ve registered your account with that email address.
                Please check for an email from New U Life and click on
                the included link to reset your password.
              </SuccessMessageBody>
            </SuccessMessage>
            <SuccessLoginLink to='/login'>
              <Button 
                fullWidth 
                variant="contained" 
                color="primary" 
                size='large'
              >
                Back to Login
              </Button>
            </SuccessLoginLink>

          </div>

        )}

        { error && <ValidationError>{error.message}</ValidationError>}

      {  !resetSent && 
        <Container>
          <HeaderTitle
            variant='h1'
          >
            Forgot Password
          </HeaderTitle>
          <HeaderSubtitle
            variant='body1'
          >
            Please enter your email address and we will send
            you an email to reset your password.
          </HeaderSubtitle>

          <StyledTextField
            autoFocus
            fullWidth
            id='email'
            label='Email'
            variant="outlined"
            margin='normal'
            type='email'
            name='email'
            value={email}
            onChange={this.handleOnChange}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size='large'
            disabled={isInvalid || isLoading}
            onClick={this.handleOnSubmit}
          >
            Reset Password
          </Button>
          <BackToLogIn
            color='secondary'
          >
            <LoginLink to='/login'>
              Back to Log In
            </LoginLink>
          </BackToLogIn>
        </Container>
      }
      </MobileWrapper>
    )
  }
}

export default withFirebase(PasswordView)
