import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components'
import { Divider } from '@material-ui/core'

const Container = styled.div`
    background-color: #fff;
    width: 339px;
    height: 453px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 90px;
    left: 0;
    right: 0;
    border: 1px solid #707070;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const ModalHeader = styled.h1`
    font-family: 'Source Sans Pro';
    font-size: 24px;
`

const BodyText = styled.p`
    font-family: 'Open Sans';
    font-size: 16px;
    text-align: center;
    width: 290px;
`
const HeaderDivider = styled(Divider)`
    background-color: #DBDBDB;
    height: 1px;
    width: 290px;
`

const WeighInModal = (props) => {
    return (
        <Container>
            <ModalHeader>
                About Weigh-in Words
            </ModalHeader>
            <HeaderDivider />
            <BodyText>
                Including your current weigh-in word with your scale photo helps our Referees verify that your photos were taken today.
            </BodyText>
            <BodyText>
                This word changes periodically, so make sure you're using the word that will be valid for this weigh-in.
            </BodyText>
            <BodyText>
                Weigh-in words help keep the game fair for everyone.
            </BodyText>
            <Button
                rounded
                variant='contained'
                onClick={props.closeModal}
            >
                Close
            </Button>
        </Container>
    );
};

export default WeighInModal;