import React from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { MobileWrapper, Button, HeaderTitle } from '../../components'
import { withAuthorization } from '../../services/Session'
import * as ROUTES from '../../constants/routes'

const DATA_RECEIVED_ICON = require('../../assets/data-received-icon.png')

const DataImg = styled.img.attrs({
  src: DATA_RECEIVED_ICON,
  alt: 'data icon'
})`
  margin: 150px 0 0 0;
  width: 110px;
  height: auto;
  `

const StyledHeader = styled(HeaderTitle).attrs({
  variant: 'h2'
})`
  font-size: 42px;
  margin-bottom: 15px;
`

const HeaderSubtitle = styled(Typography)`
  margin-bottom: 45px;
  text-align: center;
`

const InitialWeighIn = ({ history }) => {
  return (
    <MobileWrapper>
      <DataImg />
      <StyledHeader>
        You're In!
      </StyledHeader>
      <HeaderSubtitle>
        Your initial weigh-in is needed to start the game.
      </HeaderSubtitle>
      <Button
        rounded
        fullWidth
        size='large'
        variant='contained'
        color='secondary'
        onClick={() => history.push(ROUTES.ACCOUNT_WEIGH_IN)}
      >
        Submit initial weigh-in
      </Button>
    </MobileWrapper>
  )
}

const condition = authUser => !!authUser
export default compose(
  withRouter,
  withAuthorization(condition)
)(InitialWeighIn)
