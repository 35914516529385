import styled from 'styled-components'

const StyledMobileWrapper = styled.div`
  padding: 0;
  margin: 70px auto 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 310px;
`

export default StyledMobileWrapper
