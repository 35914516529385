import React from 'react'
import styled from 'styled-components'
import { Typography, TextField } from '@material-ui/core'
import { Button, Navigation, HeaderTitle, MobileWrapper } from '../../components'
import { withFirebase } from '../../services/Firebase'

const EmailInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 310px;
  margin: 0 auto;
  padding: 0;
  border-radius: 8px;
  border-bottom: none;
`

const FinePrint = styled(Typography)`
  margin: 3px 2px 20px 2px;
`

const StyledInput = styled(TextField).attrs({
  margin:'normal',
  variant:'outlined',
})`
  border-radius: 10px;
  background-color: white;
  border-bottom: none;
`

const ValidationError = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: .25rem;
`

const INITIAL_STATE = {
  email: '',
  isLoading: false,
  error: null
}

class ResetView extends React.Component {
  state = {
    ...INITIAL_STATE
  }

  handleOnChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleOnSubmit = (event) => {
    const { password1 } = this.state
    this.props.firebase
      .doPasswordUpdate(password1)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
      })
      .catch(error => {
        this.setState({ error })
      });


    event.preventDefault()
  }

  render () {
    const { password1, password2, isLoading, error } = this.state
    const isInvalid = password1 !== password2 || password1 === ''

    return (
      <MobileWrapper>
        <Navigation />
        <HeaderTitle>
          Let's Reset Your Password
        </HeaderTitle>

        { error && <ValidationError>{error.message}</ValidationError>}

        <EmailInputWrapper>
            <StyledInput
              autoFocus
              fullWidth
              id='newPassword'
              label='New Password'
              name="password1"
              type='password'
              value={password1}
              onChange={this.handleOnChange}
            />
            <StyledInput
              fullWidth
              id='confirmPassword'
              label='Confirm Password'
              name="password2"
              type='password'
              value={password2}
              onChange={this.handleOnChange}
            />
        </EmailInputWrapper>
        <FinePrint>
          Your new password cannot be a previously used password and must be at least 8 characters.
        </FinePrint>
        <Button
            fullWidth
            variant='contained'
            color='primary'
            size='large'
            onClick={this.handleOnSubmit}
            disabled={isInvalid || isLoading}
          >
            Reset Password
          </Button>
      </MobileWrapper>
    )
  }
}

export default withFirebase(ResetView)
