import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import AccountView from '../../views/Account'
import AdminView from '../../views/Admin'
import DataReceivedView from '../../views/DataReceived'
import FormView from '../../views/Form'
import LoginView from '../../views/Login'
import NotFoundView from '../../views/NotFound'
import PasswordChangeView from '../../views/PasswordChange'
import PasswordForgotView from '../../views/PasswordForgot'
import PasswordResetView from '../../views/PasswordReset'
import SignUpView from '../../views/SignUp'
import WeighInView from '../../views/WeighIn'
import WeighInstructionsView from '../../views/WeighInstructions'
import InitialWeighInView from '../../views/Weigh'
import WelcomeView from '../../views/Welcome'
import EntryPhotosView from '../../views/EntryPhotos/'
import ChallengeOverView from '../../views/ChallengeOver'
import * as ROUTES from '../../constants/routes'

const Root = styled.div`
  display: flex;
`

const Layout = () => (
  <Root>

    <Switch>
      {/* PUBLIC ROUTES */}
      <Route path={ROUTES.LOGIN} component={LoginView} />
      {/* <Route path={ROUTES.PASSWORD_FORGOT} component={PasswordForgotView} /> */}
      {/* <Route path={ROUTES.PASSWORD_RESET} component={PasswordResetView} /> */}
      {/* <Route path={ROUTES.SIGNUP} component={SignUpView} /> */}
      {/* <Route path={ROUTES.WELCOME} component={WelcomeView} /> */}

      {/* PROTECTED ROUTES */}
      <Route path={ROUTES.ACCOUNT_PASSWORD} component={PasswordChangeView} />
      <Route path={ROUTES.ACCOUNT_WEIGH_IN} component={ChallengeOverView} />
      <Route path={ROUTES.ACCOUNT} component={AccountView} />
      <Route path={ROUTES.DATA_RECEIVED} component={DataReceivedView} />
      <Route path={ROUTES.WEIGH_INSTRUCTIONS} component={WeighInstructionsView} />
      <Route path={ROUTES.WEIGH_IN_INITIAL} component={InitialWeighInView} />
      <Route path={ROUTES.SURVEY} component={FormView} />
      <Route path={ROUTES.ENTRY_PHOTOS} component={EntryPhotosView} />
      {/* ADMIN ROUTES */}
      <Route path={ROUTES.ADMIN} component={AdminView} />


      {/* ROOT ROUTE */}
      <Route exact path={ROUTES.DEFAULT}>
        <Redirect to={ROUTES.ACCOUNT_WEIGH_IN} />
      </Route>

      {/* CATCHALL ROUTE */}
      <Route component={NotFoundView} />
    </Switch>
  </Root>
)

export default Layout
