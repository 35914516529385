// public routes
export const DEFAULT = '/'
export const LOGIN = '/login'
export const PASSWORD_FORGOT = '/forgot-password'
export const PASSWORD_RESET = '/reset-password'
export const PASSWORD_SUCCESS = '/password-success'
export const SIGNUP = '/register'
export const SURVEY = '/form'
export const WELCOME = '/welcome'

// authenticated routes
export const ACCOUNT = '/my-account'
export const ACCOUNT_PASSWORD = '/my-account/change-password'
export const ACCOUNT_WEIGH_IN = '/my-account/weigh-in'
export const DATA_RECEIVED = '/data-received'
export const WEIGH_IN_INITIAL = '/initial-weigh-in'
export const WEIGH_INSTRUCTIONS = '/weigh-in-instructions'
export const ENTRY_PHOTOS = '/entry-photos'
// administrative routes
export const ADMIN = '/admin'

