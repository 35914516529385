import React from 'react'
import styled from 'styled-components'
import WhiteCheckMark from '../../assets/check-mark-white.png'
import { Divider } from '@material-ui/core'

const ChecklistWrapper = styled.div`
    background-color: #0083BE;
    height: 220px;;
    width:100%;
    height: 40%;
`

const Checklist = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 150px;
`

const ChecklistHeaderTitle = styled.h1`
    color: #FFF;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    padding-top: 20px;
    margin: 0;
    text-align: center;
`

const StyledDivider = styled(Divider)`
    background-color: #96B7FF;
    width: 300px;
    margin: 10px auto;
`

const CheckMark = styled.img.attrs({
    src: WhiteCheckMark,
})`
    height: 15px;
    width: 15px;
    margin-top: 6px;
`

const ListItemWrapper= styled.ul`
    list-style-type: none;
    display: flex;
    margin: 0 auto 3px;
    width: 310px;
    /* height:30px; */
    padding: 0;
`

const ListItem = styled.li.attrs({
})`
    margin-left: 10px;
    color: #FFF;
    font-family: 'Source Sans Pro';
    font-size: 18px;
`

const PhotoChecklist = ({listOne, listTwo, listThree}) => {
    return (
        <ChecklistWrapper>
            <ChecklistHeaderTitle>
                WEIGH-IN PHOTO CHECKLIST:
            </ChecklistHeaderTitle>
            <StyledDivider />
            <Checklist>
                <ListItemWrapper>
                    <CheckMark />
                    <ListItem>
                        {listOne}
                    </ListItem>
                </ListItemWrapper>
                <ListItemWrapper>
                    <CheckMark />
                    <ListItem>
                        {listTwo}
                    </ListItem>
                </ListItemWrapper>
                <ListItemWrapper>
                    <CheckMark />
                    <ListItem>
                        {listThree}
                    </ListItem>
                </ListItemWrapper>
            </Checklist>
        </ChecklistWrapper>
    );
};

export default PhotoChecklist;