import React from 'react'
import { compose } from 'recompose'
import firebase from 'firebase/app'
import 'firebase/functions'
import { Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import ScaleIcon from '../../assets/scale-icon@2x.png'
import WotdIcon from '../../assets/wotd-icon@2x.png'
import ScaleWordIcon from '../../assets/scale-word-icon@2x.png'
import PersonWordIcon from '../../assets/person-word-icon@2x.png'
import EnterWeightIcon from '../../assets/enter-weight-icon@2x.png'
import PersonIcon from '../../assets/person-icon@2x.png'
import { WeighInWrapper,
        PhotoHeader,
        PhotoCapture,
        InstructionText,
        PhotoChecklist,
        PhotosLoading,
        QuestionLink,
        WrittenWord,
        WordOfTheDay,
        WeighInModal }
      from '../../components'
import { withAuthorization } from '../../services/Session'
import { withFirebase } from '../../services/Firebase'
import * as ROUTES from '../../constants/routes'
import Wizard from './Wizard'

const required = value => (value ? undefined : 'Required')

const PadAndCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 0;
`

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 612px;
  font-family: 'Source Sans Pro';
  font-size: 16px;
`

const StyledField =styled(Field)`
  border-left: hidden;
  border-top: hidden;
  border-right: hidden;
  background: none;
  font-size: 60px;
  width: 250px;
  text-align: center;
  &::placeholder {
    font-size: 32px;
  }
`



const INITIAL_STATE = {
  wotd: '',
  weight: '',
  userId: '',
  email: null,
  backImg: '',
  frontImg: '',
  scaleImg: '',
  sideImg: '',
  wordImg: '',
  isLoading: false,
  openModal: false,
  canSubmit: false,
  error: null
}

class WeighInForm extends React.Component {
  state = {
    ...INITIAL_STATE
  }

  componentDidMount () {
    // TODO: fix this whole mess.
    this.props.firebase.getWordOfTheDay()
      .then((wotd) => {
        this.setState({ wotd });
      })
      .catch(error => {
        this.setState({ error });
      })

    this.props.firebase.getCurrentUserData()
      .then(({ email, userId }) => {
        this.setState({ email, userId })
        return this.props.firebase.canUserAddNewEntry(email)
      })
      .then((canSubmit) => {
        this.setState({ canSubmit })
      })
      .catch((error) => {
        console.error('ERROR', error)
        this.setState({ error })
      })
  }

  handleCapturedImage = ({ name, imageSrc }) => {
    this.setState({ [name]: imageSrc })
  }

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleOpenModal = () => {
    this.setState({ openModal: !this.state.openModal })
    this.handleBlurView();
  }

  handleBlurView = () => {
    this.state.openModal ?
    document.getElementById('wotd-view').style.filter = 'blur(0px)' :
    document.getElementById('wotd-view').style.filter = 'blur(5px)'
  }

  handleOnSubmit = ({ weight }, actions) => {
    const createWeighInMailchimpEvent = firebase.functions().httpsCallable('createWeighInMailchimpEvent')
    this.setState({ isLoading: true })
    const { userId, wotd, backImg, frontImg, scaleImg, sideImg, wordImg, email } = this.state
    const photos = { backImg, frontImg, scaleImg, sideImg, wordImg }
    this.props.firebase.createWeighInEntry(userId, wotd, weight, photos)
      .then((entry) => {
        actions.setSubmitting(false)
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.DATA_RECEIVED)
        createWeighInMailchimpEvent(email)
      })
      .catch((error) => {
        console.log('ERROR', error)
        this.setState({ error, isLoading: false })
      })
  }

  render () {
    return (
      <WeighInWrapper>
        <Wizard
          initialValues={{ ...INITIAL_STATE }}
          onSubmit={this.handleOnSubmit}>

          {/* <Wizard.Page>
            This is for the weigh-in instructions page
          </Wizard.Page> */}

          <Wizard.Page
            validate={values => {
              const errors = {};
              if (!this.state.canSubmit) {
                errors.canSubmit = 'It looks like you have already submitted a weigh-in this week. Please try again next week.';
              }
              return errors;
            }}
          >
            <div>
              <div id='wotd-view'>

                {/* <PhotoHeader icon={WotdIcon} /> */}
                <ErrorMessage
                  name="canSubmit"
                  component="div"
                  className="alert-danger"
                />

                <InstructionText>
                  Your current weigh-in word is:
                </InstructionText>
                <WordOfTheDay text={this.state.wotd} />
                <InstructionText>
                  Write this word on an index card or piece of paper with a bold marker.
                </InstructionText>
                <WrittenWord>{this.state.wotd}</WrittenWord>
                <InstructionText>
                  Be sure to use large letters that will be clearly visible in your photo.
                </InstructionText>
                <QuestionLink onClick={ this.handleOpenModal }>
                  Why am I doing this?
                </QuestionLink>
              </div>
              { this.state.openModal &&
                <WeighInModal closeModal={ this.handleOpenModal } />
              }
            </div>
          </Wizard.Page>

          <Wizard.Page
            validate={values => {
              const errors = {};
              if (!this.state.scaleImg) {
                errors.scaleImg = 'This photo is required.';
              }
              return errors;
            }}
          >
            <div>
              <PhotoHeader
                showProgress
                currentStep={this.state.page}
                icon={ScaleWordIcon}
                title='scale with weigh-in word'
              />
              <ErrorMessage
                name="scaleImg"
                component="div"
                className="alert-danger"
              />
              <PadAndCenter>
                <PhotoCapture
                  name='scaleImg'
                  onCapture={this.handleCapturedImage}
                  imageSrc={this.state.scaleImg}
                />
              </PadAndCenter>
              <PhotoChecklist
                listOne="I'm standing on the scale."
                listTwo="My weight reading is clearly visible."
                listThree="My weigh-in word is clearly written and readable."
              />
            </div>
          </Wizard.Page>

          <Wizard.Page
            validate={values => {
              const errors = {};
              if (!this.state.wordImg) {
                errors.wordImg = 'This photo is required.';
              }
              return errors;
            }}
          >
            <div>

              <PhotoHeader
                icon={PersonWordIcon}
                showProgress
                currentStep={2}
                title='standing on scale with weigh-in word'
              />
              <ErrorMessage
                name="wordImg"
                component="div"
                className="alert-danger"
              />
              <PadAndCenter>
                <PhotoCapture
                  name='wordImg'
                  onCapture={this.handleCapturedImage}
                  imageSrc={this.state.wordImg}
                />
              </PadAndCenter>
              <PhotoChecklist
                listOne="I'm standing on the scale."
                listTwo="I'm wearing lightweight clothing.(No shoes, outerwear, or accessories)"
                listThree="My entire face and body are visible"
              />
            </div>
          </Wizard.Page>

          <Wizard.Page
            validate={values => {
              const errors = {};
              if (!values.weight) {
                errors.weight = 'You must enter your current weight.';
              }
              return errors;
            }}
          >
            <div>
              <PhotoHeader
                showProgress
                currentStep={3}
                icon={EnterWeightIcon}
                title='enter your current weight'
              />
              <ErrorMessage
                name="weight"
                component="div"
                className="alert-danger"
              />
              <FieldContainer>
                <div>
                  <StyledField
                    name="weight"
                    component="input"
                    type="number"
                    validate={required}
                  />lbs
                </div>

                <InstructionText>
                  Enter your weight exactly how it appears on the scale.
                </InstructionText>
              </FieldContainer>

            </div>
          </Wizard.Page>

          <Wizard.Page
            validate={values => {
              const errors = {};
              if (!this.state.frontImg) {
                errors.frontImg = 'This photo is required.';
              }
              return errors;
            }}
          >
            <div>
              <PhotoHeader
                showProgress
                icon={PersonIcon}
                title='front facing photo'
              />
              <ErrorMessage
                name="frontImg"
                component="div"
                className="alert-danger" />
              <PadAndCenter>
                <PhotoCapture
                  name='frontImg'
                  onCapture={this.handleCapturedImage}
                  imageSrc={this.state.frontImg}
                />
              </PadAndCenter>
              <PhotoChecklist
                listOne="I'm facing the camera in a well-lit room."
                listTwo="I'm wearing lightweight clothing.(No shoes, outerwear, or accessories)"
                listThree="My entire face and body are visible"
              />
            </div>
          </Wizard.Page>

          <Wizard.Page
            validate={values => {
              const errors = {};
              if (!this.state.sideImg) {
                errors.sideImg = 'This photo is required.';
              }
              return errors;
            }}
          >
            <div>
              <PhotoHeader
                showProgress
                icon={PersonIcon}
                title='side facing photo'
                currentStep={2}
              />
              <ErrorMessage
                name="sideImg"
                component="div"
                className="alert-danger" />
              <PadAndCenter>
                <PhotoCapture
                  name='sideImg'
                  onCapture={this.handleCapturedImage}
                  imageSrc={this.state.sideImg}
                />
              </PadAndCenter>
              <PhotoChecklist
                listOne="I'm standing with my side facing the camera in a well-lit room."
                listTwo="I'm wearing lightweight clothing.(No shoes, outerwear, or accessories)"
                listThree="My entire face and body are visible"
              />
            </div>
          </Wizard.Page>

          <Wizard.Page
            validate={values => {
              const errors = {};
              if (!this.state.backImg) {
                errors.backImg = 'This photo is required.';
              }
              return errors;
            }}
          >
          { !this.state.isLoading &&
            <div>
              <PhotoHeader
                showProgress
                icon={PersonIcon}
                currentStep={3}
                title='back facing photo'
              />
              <ErrorMessage
                name="backImg"
                component="div"
                className="alert-danger"
              />
              <PadAndCenter>
                <PhotoCapture
                  name='backImg'
                  onCapture={this.handleCapturedImage}
                  imageSrc={this.state.backImg}
                />
              </PadAndCenter>
              <PhotoChecklist
                listOne="I'm standing with my back to the camera in a well-lit room."
                listTwo="I'm wearing lightweight clothing.(No shoes, outerwear, or accessories)"
                listThree="My entire body is visible."
              />
            </div>
          }
          { this.state.isLoading && 

            <div>
              <PhotoHeader
                showProgress
                icon={PersonIcon}
                currentStep={3}
                title='back facing photo'
              />
              <PhotosLoading />
            </div>

          }
          </Wizard.Page>

        </Wizard>
      </WeighInWrapper>
    )
  }
}

const condition = authUser => !!authUser
export default compose(
  withFirebase,
  withAuthorization(condition)
)(WeighInForm)
