import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Navigation, MobileWrapper, InstructionText, HeaderTitle } from '../../components'

const ChallengeOver = () => {
    return (
        <MobileWrapper>
            <Navigation />
            <HeaderTitle>
            Thanks for participating in the Whole Body Challenge! 
            </HeaderTitle>
            <InstructionText>
            We’re evaluating all of our participants and we will be announcing winners soon. If you have any questions, please see our FAQ at <a href='https://www.wholebodychallenge.com'>wholebodychallenge.com</a>
            </InstructionText>
        </MobileWrapper>
    );
};

export default ChallengeOver;