import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { MobileWrapper, HeaderTitle } from '../../components'
import * as ROUTES from '../../constants/routes'

const LoginFooterWrapper = styled(Typography)`
  text-align: center;
  margin-top: 10px;
  width: 310px;
  font-size: 15px;
`

class NotFoundView extends React.Component {
  render () {
    return (
      <MobileWrapper>
        <HeaderTitle>
          Page Not Found
        </HeaderTitle>
        <LoginFooterWrapper variant='body1'>
          <Link to={ROUTES.LOGIN}>take me home</Link>
        </LoginFooterWrapper>
      </MobileWrapper>
    )
  }
}

export default NotFoundView
