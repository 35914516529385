import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Bar = styled.ul`
  height: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 300px;
  &::after {
      content: "";
      top:50%;
      width: 100%;
      height: 2px;
      position: absolute;
      background-color: #ffffff;
  }
`

const Dot = styled.li`
  background: #4d4f53;
  border-color: #ffffff;
  border-radius: 100px;
  border-style: solid;
  border-width: 2px;
  width: 18px;
  height: 18px;
  z-index: 10;
  position: relative;
  &.complete,
  &.current {
      background: #ff6d22;
  }
`

const ProgressBar = ({ currentStep, totalSteps }) => {
  if (totalSteps <= 0) {
    return null
  }

  const dots = []
  for (let i = 1; i <= totalSteps; i++) {
    dots.push(<Dot className={i === currentStep ? 'current' : (i < currentStep ? 'complete' : '')} />)
  }

  return (
    <Bar>
      {dots}
    </Bar>
  )
}

ProgressBar.defaultProps = {
  currentStep: 1,
  totalSteps: 3
}
ProgressBar.propTypes = {
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number
}

export default ProgressBar
