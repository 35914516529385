import React from 'react'
import styled from 'styled-components'
import { withAuthorization } from '../../services/Session'
import { withFirebase } from '../../services/Firebase'
import { compose } from 'recompose'
import moment from 'moment'

// import { AdminModal } from '../../components'

import firebase from 'firebase/app'

const HeaderText = styled.h2`
    text-align: center;
    display: inline-block;
    margin: 5px;
`
const StyledTable = styled.table`
/* width: 750px; */
text-align: left;
  position: relative;
  border-collapse: collapse;
  table-layout: fixed;
  /* width: 300px; */
`

const StyledTh = styled.th`
    padding: 5px;
    width: 200px;
    /* margin: 100px; */
    /* color: red; */
    text-align: left;
`

const StyledTableHead = styled.thead`
    width: 100%;
`

const StyledTr = styled.tr`
    background: ${props => props.isOdd? "#fff" : "#E0E0E0"};
    &:hover {
        cursor: pointer;
        font-weight: bolder;
    }
`

const StickyTr = styled.tr`
    position: sticky;
    top: 0;
`

const StyledTd = styled.td`
    width: 100px;
    padding: 0;
    border: none;
    /* border-style: hidden; */
`

const EntryWeight = styled.div`
    font-size: 14pt;
    font-weight: bold;
`

const UserModal = styled.div`
    background-color: #69B2D3;
    width: 700px;
    height: auto;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 90px;
    left: 0;
    right: 0;
    border: 1px solid #707070;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: orange; */
    margin-bottom: 25px;
    width: 90%;
`

const PhotoWrapper = styled.div`
  /* cover the modal */
  /* position: absolute;
  top: 20px;
  right: 0;
  width: 40%;
  height: 100%; */

  /* spacing as needed */
  /* padding: 20px 50px 20px 20px; */

  /* let it scroll */
  overflow: scroll;
`

const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
`

const StyledImg = styled.img`
    max-height: 450px;
    max-width: 450px;
    margin-bottom: 20px;
    display: block;
`

class AdminTable extends React.Component {

    state = {
        headerKeys: ['FIRST NAME', 'LAST NAME', 'EMAIL ADDRESS', 'GENDER', 'AGE', 'HEIGHT', 'START WEIGHT', 'Entries', 'LBS. LOST' ],
        userEntries: [],
        currentUserView: null
    }

    getKeys = () => {
        return Object.keys(this.props.userData[0])
    }

    getHeaders = () => {
        let keys = this.state.headerKeys

        return keys.map( (header, index) => {
            return (
            <StyledTh key={index}>
                {header}
            </StyledTh>
            )
        })
    }

    closeModal = () => {

        console.log('CLOSSSSSEEEE')
        this.setState({ userEntries: [] })
    }

    displayPhotos = (index) => {
        console.log(this.state.userEntries[index])
        // return <div>{this.state.userEntries[index].photos.map( photo => <StyledImg src={photo.wordUrl} />)}</div>
    }

    fetchUserEntries = (user) => {
        console.log(user)
        this.props.firebase.getUserEntriesByEmail(user.email)
        .then(response => this.setState({ userEntries: [...Object.values(response)], currentUserView: {first: user.fName, last: user.lName, id: user.id} }, () => {
            console.log(this.state.userEntries)
        })
        )
        .catch( error => error)
      }



    getRows = (num) => {
        let users = this.props.userData.slice(0,num);
        // console.log(users)
        return users.map( (user, index) => {
            return <StyledTr key={`rowKey = ${index}`} isOdd={Boolean(index%2)} onClick={() => this.fetchUserEntries(user)}>
                    {/* <StyledTd>{user.id}</StyledTd> */}
                    <StyledTd>{user.fName}</StyledTd>
                    <StyledTd>{user.lName}</StyledTd>
                    <StyledTd>{user.email}</StyledTd>
                    <StyledTd>{user.gender}</StyledTd>
                    <StyledTd>{user.regAge}</StyledTd>
                    <StyledTd>{user.regHeight}</StyledTd>
                    <StyledTd>{user.regCurrWeight}</StyledTd>
                    <StyledTd>{user.numEntries}</StyledTd>
                    <StyledTd>{user.lbsLost}</StyledTd>
                </StyledTr>
        })
    }

    calcRows = () => {

    }


    
    render() {

        const UserDisplay = this.state.userEntries.map( (entry, index) => {

            return <ModalWrapper key={`date = ${index}`}>
                        
                        <div>
                            {moment(entry.dateCreated).format('dddd, MMMM Do, YYYY')}
                        </div>
                        <EntryWeight>
                            {entry.weight}lbs
                        </EntryWeight>
                        <PhotoWrapper>
                            <StyledImg src={entry.photos.wordUrl} />
                            <StyledImg src={entry.photos.scaleUrl} />
                            <StyledImg src={entry.photos.frontUrl} />
                            <StyledImg src={entry.photos.sideUrl} />
                            <StyledImg src={entry.photos.backUrl} /> 
                        </PhotoWrapper>
                    </ModalWrapper>
        })

        return (
            <div>
                
                <StyledTable>
                    <StyledTableHead>
                        
                    <StickyTr>{this.getHeaders()}</StickyTr>

                    </StyledTableHead>
                    <tbody>
                    {this.getRows(30)}
                    </tbody>
                </StyledTable>


                { this.state.userEntries.length && 
                    <UserModal>
                        <div>
                            <HeaderText>
                                {this.state.currentUserView.first}

                            </HeaderText>
                            <HeaderText>
                            {this.state.currentUserView.last}
                            </HeaderText>
                            <div>
                                ID: {this.state.currentUserView.id}
                            </div>
                        </div>

                        <CloseButton onClick={this.closeModal}>
                            Close
                        </CloseButton>
                        {UserDisplay}
                    </UserModal>
                    // <AdminModal 
                    //     entries={this.state.userEntries}
                    // />
                }
            </div>
        
        );
    }
   }

export default compose (withFirebase)(AdminTable)
