import React from 'react';
import styled from 'styled-components'
import MobileWrapper from '../MobileWrapper'

const WeighInWrapper = styled(MobileWrapper)`
    position: relative;
    margin-top: 0;
    height: 100%;
`

export default WeighInWrapper;