import React from 'react'
import styled from 'styled-components'
import MatAppBar from '@material-ui/core/AppBar'
import { Toolbar } from '@material-ui/core'
import MobileButton from '../../assets/mobile-button-icon@2x.png'
import NewULogo from '../../assets/new-u-green-blue@2x.png'
import Drawer from './Drawer'

const AppBar = styled(MatAppBar).attrs({
  position: 'fixed',
  eleveation: 0
})`
  background-color: #fff;
  box-shadow: 0 1px 3px #E5E5E5;
`

const NewUIcon = styled.img.attrs({
  src: NewULogo
})`
  position: fixed;
  right: 7px;;
`

const MobileMenuIcon = styled.img.attrs({
  src: MobileButton
})`
  height: 56px;
  width: 65px;
`

const Navigation = () => {
  const [open, setOpen] = React.useState(false)
  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)
  return (
    <div>
      <AppBar>
        <Toolbar disableGutters='true'>
          <MobileMenuIcon onClick={handleDrawerOpen} />
          <NewUIcon />
        </Toolbar>
      </AppBar>
      <Drawer open={open} handleDrawerClose={handleDrawerClose} />
    </div>
  )
}

export default Navigation
