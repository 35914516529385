import React from 'react';
import ReactLoading from 'react-loading'
import styled from 'styled-components'
import { HeaderTitle } from '../../components'

const StyledContainer = styled.div`
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StyledLoader = styled(ReactLoading)`
    margin: 117px auto 340px;
`

const PhotosLoading = () => {
    return (
        <StyledContainer>
            <HeaderTitle>
                Please wait while we submit your photos.
            </HeaderTitle>
            <div>
                <StyledLoader
                    type='bubbles'
                    color='#FF6D22'
                    width={150}
                    height={75}
                />
            </div>
        </StyledContainer>
    );
};

export default PhotosLoading;