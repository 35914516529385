import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CameraAltOutlined from '@material-ui/icons/CameraAltOutlined'
import Button from '../Button'

const Border = styled.div`
  border-color: #C1C1C1;
  border-radius: 0.2rem;
  border-style: dashed;
  border-width: 0.2rem;
  margin-bottom: 1rem;
  align-items: center;
  align-self: center;
  justify-content: center;
  display: flex;
  height: 266px;
  width: 200px;
`

const CameraIcon = styled(CameraAltOutlined)`
  font-size: 89px;
  color: #C1C1C1;
`

const Container = styled.div`
  width: 200px;
`

const Controls = styled.div`
  justify-content: center;
  align-items: center;
  width: 200px;
`

const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
  height: auto;
`

const NoImageContainer = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
`
const NoImageInstructions = styled.p`
  color: #7C7C7C;
  font-family: Open-Sans, sans-serif;
  font-size: 14pt;
  font-weight: 400;
  text-align: center;
`

class PhotoCapture extends React.Component {
  static defaultProps = {}
  static propTypes = {
    name: PropTypes.string.isRequired,
    onCapture: PropTypes.func.isRequired,
    imageSrc: PropTypes.string
  }

  convertToBase64 = (image) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  handleImageReset = () => {
    const name = this.props.name
    this.props.onCapture({ name, imageSrc: null })
  }

  handleImageUpload = (event) =>
    this.convertToBase64(event.target.files[0])
      .then((imageSrc) => {
        const name = this.props.name
        this.props.onCapture({ name, imageSrc })
      })
      .catch((error) => console.error(error))

  componentDidMount () {
  }

  render () {
    return (
      <Container>
        <Border>
          {this.props.imageSrc ? (
            <Image alt={this.props.name} src={this.props.imageSrc} />
          ) : (
            <NoImageContainer>
              <CameraIcon />
              <NoImageInstructions>
                Click the button below to take or upload a photo.
              </NoImageInstructions>
            </NoImageContainer>
          )}
        </Border>

        <Controls>
          {this.props.imageSrc ? (
            <Button rounded variant='outlined' onClick={this.handleImageReset}>
              Retake/Replace Photo
            </Button>
          ) : (
            <input type='file' accept='image/*' onChange={this.handleImageUpload} />
          )}
        </Controls>
      </Container>
    )
  }
}

export default PhotoCapture
