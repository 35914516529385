import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import ConfiguredApp from './containers/App'
import Firebase, { FirebaseContext } from './services/Firebase'
import * as serviceWorker from './serviceWorker'

// Provides the Firebase context to the entire app.
const FirebaseApp = () => (
  <FirebaseContext.Provider value={new Firebase()}>
    <ConfiguredApp />
  </FirebaseContext.Provider>
)

ReactDOM.render(<FirebaseApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
