import styled from 'styled-components'
import { Typography } from '@material-ui/core'

const HeaderTitle = styled(Typography).attrs({
  variant: 'h1'
})`
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
`

export default HeaderTitle
