import React from 'react';
import styled from 'styled-components'
import moment from 'moment'

const UserModal = styled.div`
    background-color: #fff;
    width: 75vw;
    height: 500px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 300px;
    left: 0;
    right: 0;
    border: 1px solid #707070;
    display: flex;
    justify-content: space-between;
    padding: 20px;
`
const StyledImg = styled.img`
    max-height: 450px;
    max-width: 450px;
    margin-bottom: 20px;
    display: block;
`

const UserInfo = styled.section`
    margin-top: 25px;
`

const DateListed = styled.div`
    font-weight: bold;
    margin-top: 10px;
    &&:hover {
        cursor: pointer;
    }
`

const PhotoWrapper = styled.div`
  margin-top: 25px;
  overflow: scroll;
`

const WeightWrapper = styled.span`
    font-size: 16pt;
    font-weight: normal;
`
const DateWrapper = styled.h3`
    text-align: center;
    color: #0083BE;
`

const CloseButton = styled.button`
    /* position: absolute;
    top: 0;
    right: 0; */
    font-size: 16pt;
    width: 30px;
    height: 30px;
    background-color: orange;
    color: white;
    /* display: inline-block; */
`

const CloseTextWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0px;
    align-items: center;
    /* border: 1px solid red; */
    /* width: 40px; */
`
const CloseText = styled.p`
    margin: 0 5px 0 0;
    height: 25px;
`
const UserName = styled.h2`
    margin-top: 0;
`
class AdminModal extends React.Component {


    render () {

        console.log(this.props)
        const { userToDisplay, entriesToDisplay, setUserToDisplay, entryPhotos, updateEntryToDisplay } = this.props

        const entryDates = entriesToDisplay.map( (entry, index) => 
            <DateListed key={`userEntry + ${index}`} onClick={()=>updateEntryToDisplay(index)}>
                <WeightWrapper>{entry.weight}lbs</WeightWrapper> {moment(entry.dateCreated).format('dddd, MMMM Do, YYYY')}
                </DateListed>
            )

        const modalFragment = (
                <UserModal>
                    <CloseTextWrapper>
                    <CloseButton
                        onClick={setUserToDisplay}
                    >
                        X
                    </CloseButton>
                    </CloseTextWrapper>
                    <UserInfo>
                        <UserName>
                            <WeightWrapper>{userToDisplay.fName} {userToDisplay.lName}</WeightWrapper>
                        </UserName>
                        <div>
                            {userToDisplay && userToDisplay.email}
                        </div>
                        <div>
                            ID: {userToDisplay.id}
                        </div>
                        <div>
                            Weight lost to date: <WeightWrapper>{userToDisplay.lbsLost}lbs</WeightWrapper>
                        </div>
                        <div>
                            {entryDates}
                        </div>
                    </UserInfo>
                    {
                        entryPhotos && 
                        <PhotoWrapper>
                            <DateWrapper>{moment(entryPhotos.dateCreated).format('dddd, MMMM Do, YYYY')}</DateWrapper>
                            <StyledImg src={entryPhotos.photos.wordUrl} />
                            <StyledImg src={entryPhotos.photos.scaleUrl} />
                            <StyledImg src={entryPhotos.photos.frontUrl} />
                            <StyledImg src={entryPhotos.photos.sideUrl} />
                            <StyledImg src={entryPhotos.photos.backUrl} /> 
                        </PhotoWrapper>
                    }
                    {              
                    !entryPhotos &&      
                    <PhotoWrapper>
                        <DateWrapper>{moment(entriesToDisplay[entriesToDisplay.length -1].dateCreated).format('dddd, MMMM Do, YYYY')}</DateWrapper>
                        <StyledImg src={entriesToDisplay[entriesToDisplay.length -1].photos.wordUrl} />
                        <StyledImg src={entriesToDisplay[entriesToDisplay.length -1].photos.scaleUrl} />
                        <StyledImg src={entriesToDisplay[entriesToDisplay.length -1].photos.frontUrl} />
                        <StyledImg src={entriesToDisplay[entriesToDisplay.length -1].photos.sideUrl} />
                        <StyledImg src={entriesToDisplay[entriesToDisplay.length -1].photos.backUrl} /> 
                    </PhotoWrapper>
                    }

                </UserModal>
        )

        return (
            <React.Fragment>
                {userToDisplay && entriesToDisplay && modalFragment}
            </React.Fragment>
        );
    }

};

export default AdminModal;