import React from 'react'
import { withFirebase } from '../../services/Firebase'

import Button from '../Button'

const SignOutButton = ({ firebase }) => (
  <Button type="button" onClick={firebase.doSignOut}>
    Sign Out
  </Button>
)

export default withFirebase(SignOutButton)
