import React from 'react';
import styled from 'styled-components'

const InstructionText = styled.h2`
    width: 300px;
    font-family: 'Open Sans';
    font-size: 16px;
    color: #4D4F53;
    text-align: center;
    font-weight: 600;
    margin: 15px auto;
`

export default InstructionText;