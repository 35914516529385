import React from 'react';
import styled from 'styled-components'
import InstructionPhoto1 from '../../assets/instruction-photo-one@2x.png'
import InstructionPhoto2 from '../../assets/instruction-photo-two.png'
import { WideContinueButton } from '../../components'
import { Link } from 'react-router-dom'
import { MobileWrapper } from '../../components'

const InstructionText = styled.h2`
    font-family: 'Open Sans';
    font-size: 16px;
    color: #4D4F53;
    text-align: center;
    font-weight: 600;
`

const PhotoText = styled.span`
    color: #0D81BE;
`
const InstructionPhoto = styled.img.attrs({
    src: InstructionPhoto1,
})`
    margin-bottom: 10px;
`

const WeighInstructions = () => {
    return (
        <MobileWrapper>
            <InstructionText>
                To weigh in, you will need to send our referees <PhotoText>2 Photos</PhotoText>
            </InstructionText>
            <InstructionPhoto alt='scale'/>
            <img src={InstructionPhoto2} alt='body on scale'/>
            <Link to='/my-account/weigh-in'>
                <WideContinueButton text='Continue to weigh-in word' />
            </Link>
        </MobileWrapper>
    );
};

export default WeighInstructions;