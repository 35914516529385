import React from 'react'
import { Formik } from 'formik'
import styled from 'styled-components'

const CHEVRON_RIGHT = require('../../assets/continue-arrow@2x.png')

const IconChevronRight = styled.img.attrs({
  src: CHEVRON_RIGHT
})`
    height: 16px;
    margin-left: 14px;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100vw;
`

class Wizard extends React.Component {
  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      values: props.initialValues,
      page: 0
    }
  }

  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }))

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }))

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = (values, bag) => {
    const { page } = this.state
    const { children, onSubmit } = this.props
    const isLastPage = page === React.Children.count(children) - 1

    if (isLastPage) {
      return onSubmit(values, bag)
    } else {
      bag.setTouched({})
      bag.setSubmitting(false)
      this.next(values)
    }
  }

  render() {
    const { children } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1
    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        render={({  handleSubmit, isSubmitting, handleReset }) => (
          <form onSubmit={handleSubmit}>
            {console.log(page)}
            {activePage}
            <ButtonContainer>
              { page === 0  && (
                <button
                  type='submit'
                  className='wizard-btn-continue-fw'
                >
                  Continue
                  <IconChevronRight />
                </button>
              )}
              { (page > 0 && !isLastPage) && (
                  <button
                    type="button"
                    className="wizard-btn-back"
                    onClick={this.previous}
                  >
                    Back
                  </button>
              )}
              { (page > 0 && !isLastPage) && (
                <button
                type="submit"
                className='wizard-btn-continue'
                >
                  Continue
                  <IconChevronRight />
              </button>
              )}

              {/* {!isLastPage && (

              )} */}
              {isLastPage && (
                <button
                  type="submit"
                  className='wizard-btn-submit'
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              )}
            </ButtonContainer>
          </form>
        )}
      />
    )
  }
}

export default Wizard
