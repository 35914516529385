import React, { Component } from 'react'
import { Navigation, Button, MobileWrapper } from '../../components'
import { withFirebase } from '../../services/Firebase'
import firebase from 'firebase/app'
import { withAuthorization } from '../../services/Session'
import { compose } from 'recompose'
import downArrow from '../../assets/down-arrow.png'
import upArrow from '../../assets/up-arrow.png'
import styled from 'styled-components'
import Img from 'react-fix-image-orientation'
import moment from 'moment'

const StyledMobileWrapper = styled(MobileWrapper)`
  margin: 50px auto 0;
`

const StyledProgressBar = styled.div`
  width: 100vw;
  height: 60px;
  background-color: #4D4F53;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`

const StyledImg = styled(Img)`
    max-height: 350px;
    max-width: 350px;
    margin-bottom: 20px;
`

const EntryContainer = styled.div`
    width: 100vw;
    border-bottom: 1px solid #FF6D22;
`

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const EntryHeader = styled.h1`
    padding: 10px 0 10px 0;
    font-family: 'Source Sans Pro';
    font-size: 14pt;
    text-align: center;
    max-width: 320px;
    margin: 0 auto;
`

const UpArrow = styled.img.attrs({
    src: upArrow
})`
    height: 20px;
`

const DownArrow = styled.img.attrs({
    src: downArrow
})`
    height: 20px;
`

class EntryPhotosView extends Component {

    state = {
        entries: [],
        showPhotos: null,
        photosToDisplay: []
    }

    componentDidMount () {
        this.handleUserEntries()
    }

    handleUserEntries = () => {
        this.props.firebase.getUserEntriesByEmail(this.props.authUser.email)
        .then(response => this.setState({ entries: [...Object.values(response)]}, () => this.setState({ showPhotos: this.state.entries.length})))
        .catch( error => error)
    }

    render() {

        const entryOptions = this.state.entries.map ( (entry, index) => {
            let week = index + 1
            
            return (
                <EntryContainer key={`Dropdown - ${index}`} >
                    <EntryHeader>
                        Week {week} - {moment(entry.dateCreated).format('MMMM Do YYYY')} - {entry.weight}lbs
                        <Button
                            onClick={() => this.state.showPhotos === week ? this.setState({showPhotos: null}) : this.setState({showPhotos: week})}
                        >
                            {this.state.showPhotos === week ? <UpArrow /> : <DownArrow /> }
                        </Button>
                    </EntryHeader>

                {   this.state.showPhotos === week &&
                    <ImageContainer>
                        <h4>Word of The Day and Scale</h4>
                        <StyledImg src={entry.photos.wordUrl} />

                        <h4>Standing on Scale</h4>
                        <StyledImg src={entry.photos.scaleUrl} />

                        <h4>Front Facing Photo</h4>
                        <StyledImg src={entry.photos.frontUrl} />

                        <h4>Side Facing Photo</h4>
                        <StyledImg src={entry.photos.sideUrl} />

                        <h4>Back Facing Photo</h4>
                        <StyledImg src={entry.photos.backUrl} />

                    </ImageContainer>

                }
                </EntryContainer>
            )

        })
        return (
            <StyledMobileWrapper>
                <Navigation />
                <StyledProgressBar>
                    Photo Review
                </StyledProgressBar>
            {entryOptions}
            </StyledMobileWrapper>
        )
    }
}

const condition = authUser => !!authUser
export default compose (withFirebase, withAuthorization(condition))(EntryPhotosView)

