import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MaterialButton from '@material-ui/core/Button'

const RoundButton = styled(MaterialButton)`
  border-radius: 20px;
`

const StyledButton = (props) => {
  if (props.rounded) {
    const cleanedProps = ({ rounded, ...rest }) => rest
    return <RoundButton disableElevation {...cleanedProps(props)} />
  }
  return <MaterialButton disableElevation {...props} />
}

StyledButton.defaultProps = {}
StyledButton.propTypes = {
  rounded: PropTypes.bool
}

export default StyledButton
