import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Button, Navigation, MobileWrapper, HeaderTitle } from '../../components'
import { withFirebase } from '../../services/Firebase'
import { Link }from 'react-router-dom'
import * as ROUTES from '../../constants/routes'

const INITIAL_STATE = {
  email: '',
  password: '',
  isLoading: false,
  error: null
}

const ValidationError = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: .25rem;
`

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
`

const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  color: #0083BE;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const EmailInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 310px;
  margin: 20px auto;
  padding: 0;
  border-radius: 8px;
  border-bottom: none;
`

const StyledInput = styled(TextField).attrs({
  margin:'normal',
  variant:'outlined',
})`
  border-radius: 10px;
  background-color: white;
  border-bottom: none;
`

const LoginFooterWrapper = styled(Typography)`
  text-align: center;
  margin-top: 10px;
  width: 310px;
  font-size: 15px;
`

const PrivacyPolicyWrapper = styled(LoginFooterWrapper)`
  font-size: 12px;
`

const SignUpLink = styled(Link)`
  text-decoration: none;
  color: #FF6D22;
  font-weight: bold;
`

const RulesLink = styled.a`
  text-decoration: none;
  color: #95989A;
`

const ForgotPasswordWrap = styled.div`
  margin-left: auto;
`

const ForgotPasswordContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
`

const ForgotPasswordLink = styled(SignUpLink)`
  font-weight: normal;
`

class LoginView extends React.Component {
  state = {
    ...INITIAL_STATE
  }

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleOnSubmit = (event) => {
    const { email, password } = this.state
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.props.history.push(ROUTES.ACCOUNT)
      })
      .catch(error => {
        this.setState({ error, isLoading: false })
      })

    event.preventDefault()
  }

  render() {
    const { email, password, isLoading, error } = this.state
    const isInvalid = password === '' || email === ''

    return (
      <MobileWrapper>
        <Navigation />
        { error && <ValidationError>{error.message}</ValidationError>}
        <HeaderTitle>
            Login To New U LIFE
        </HeaderTitle>
        <LoginFooterWrapper
            variant='body1'
          >
            New to New U Life?<SignUpLink to='register'>  Sign Up</SignUpLink>
        </LoginFooterWrapper>
        <form>
          <EmailInputWrapper>
            <StyledInput
              fullWidth
              variant='outlined'
              id='email'
              label='Email Address'
              name="email"
              type='email'
              value={email}
              onChange={this.handleOnChange}
            />
            <StyledInput
              fullWidth
              variant='outlined'
              id='password'
              label='Password'
              name="password"
              type='password'
              value={password}
              onChange={this.handleOnChange}
            />
            <ForgotPasswordContainer>
              <ForgotPasswordWrap>
                <ForgotPasswordLink to='/forgot-password'> 
                  Forgot Password? 
                </ForgotPasswordLink>
              </ForgotPasswordWrap>
            </ForgotPasswordContainer>
          </EmailInputWrapper>
          <ButtonWrapper>
            <Button
              fullWidth
              size='large'
              variant='contained'
              color='primary'
              disabled={isInvalid || isLoading}
              onClick={this.handleOnSubmit}
            >
              Log In
            </Button>
            {isLoading && <ButtonProgress size={24} />}
          </ButtonWrapper>


        </form>
        
        <PrivacyPolicyWrapper>
            By signing up you accept New U Life's <RulesLink href='https://www.wholebodychallenge.com/rules-and-regulations'>Rules</RulesLink>, <RulesLink>Terms of Use</RulesLink>, and <RulesLink href='https://www.wholebodychallenge.com/privacy'>Privacy Policy</RulesLink>.
          </PrivacyPolicyWrapper>
      </MobileWrapper>
    )
  }
}

const ComposedLoginView = compose(
  withRouter,
  withFirebase
)(LoginView)

export default ComposedLoginView
