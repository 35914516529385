import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import greenCheckIcon from '../../assets/check-green.png'
import closeIcon from '../../assets/close-x.png'

const EntryCard = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 10px;
    margin-bottom: 10px;
`

const EntryWrapper = styled.div`
    width: 100%;
`

const EntriesTitle = styled.div`
    display: flex;
    justify-content: space-between;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    font-weight: lighter;
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 10px;
    margin-bottom: 10px;
`

const WeightEntry = styled.div`
    font-family: 'Open Sans';
    font-size: 12px;
    font-weight: 600;
`

const StatusCheckGreen = styled.div`
    height: 25px;
    width: 25px;
    margin-top: 5px;
    background-image: url(${greenCheckIcon});

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`

const StatusIconRed = styled(StatusCheckGreen)`
    background-image: url(${closeIcon});
`

const Entries = ({Entries}) => {
    const EntriesToDisplay = Entries.map(
    (entry, index) => { 

        let status = false
        let photoArray = Object.values(entry.photos)

        if (photoArray.length === 5) {
            status = true;
        }
        return <EntryCard>
                    <div>
                        <WeightEntry>{entry.weight} lbs (Week {(index + 1)})</WeightEntry>
                        <div>{moment(entry.dateCreated).format('dddd, MMMM Do YYYY')}</div>
                    </div>
                    <div>
                        { status ?  
                            <StatusCheckGreen />
                            : <StatusIconRed />
                        }
                    </div>
                </EntryCard>}
        )
    return (
        <EntryWrapper>
            <EntriesTitle>
                <div>Entries</div>
                <div>Status</div>
            </EntriesTitle>
            {EntriesToDisplay}
        </EntryWrapper>
    );
};

export default Entries;