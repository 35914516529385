import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { Navigation, MobileWrapper, UserDashboard, Entries, Button} from '../../components'
import { withAuthorization } from '../../services/Session'
import { withFirebase } from '../../services/Firebase'
import { Link } from 'react-router-dom'
import firebase from 'firebase/app'

const StyledProgressBar = styled.div`
  width: 100vw;
  height: 60px;
  background-color: #4D4F53;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`

const StyledMobileWrapper = styled(MobileWrapper)`
  margin: 50px auto 0;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  margin-top: auto;
`

const ReviewButton = styled(Button)`
  margin: 20px 0 20px 0;
  width: 330px;
`

class AccountView extends React.Component {

  state = {
    userEmail: this.props.authUser.email,
    userEntries: [],
    startWeight: null,
    currentWeight: null,
    isAdmin: false
  }

  async componentDidMount () {
    this.handleUserEntries()
  }

  handleUserEntries = () => {
    console.log(this.props.authUser.email)
    this.props.firebase.getUserEntriesByEmail(this.props.authUser.email)
    .then(response => this.setState({ userEntries: [...Object.values(response)] }, () => {
      this.getStartandCurrentWeights();
      })
    )
    .catch( error => error)
  }

  getStartandCurrentWeights = () => {
    let entries = this.state.userEntries
    let weights = Object.assign( {}, {start: entries[0].weight, current: entries[entries.length -1].weight})
    this.setState({ startWeight: weights.start, currentWeight: weights.current })
  }

  getCurrentWeight = (entries) => {
    return entries[entries.length -1].weight
  }


  render () {
    // authUser should always be populated here.
    return (
      <StyledMobileWrapper>
        <Navigation />
        <StyledProgressBar>
          Progress Overview
        </StyledProgressBar>
        <UserDashboard 
          weightData = {this.state.userEntries}
          startWeight = {this.state.startWeight}
          currentWeight = {this.state.currentWeight}
        />
        <Entries 
          Entries = {this.state.userEntries}
        />
        <StyledLink to='/entry-photos'>
          <ReviewButton
            variant='contained'
            color='secondary'
          >
            Review Your Entry Photos
          </ReviewButton>
        </StyledLink>

      </StyledMobileWrapper>
    )
  }
}

const condition = authUser => !!authUser
export default compose (withFirebase, withAuthorization(condition))(AccountView)
