import React from 'react';
import styled from 'styled-components'

const StyledHeader = styled.div`
    height: 113px;
    width: 375px;
    background-color: #4D4F53;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledImg = styled.img`
    height: 59px;
    width: auto;
`

const DarkHeader = (props) => {
    return (
        <StyledHeader>
            <StyledImg src={props.icon} />
        </StyledHeader>
    )
}

export default DarkHeader;