import React from 'react'

import AuthUserContext, {AuthUserDataContext} from "./context"
import { withFirebase } from "../Firebase"

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    state = {
      authUser: null
    }

    componentDidMount () {
      this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          this.setState({ authUser })
          this.props.firebase.getCurrentUserData().then((user) => this.setState({ authUserData: user }))
        }
      })
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <AuthUserDataContext.Provider value={this.state.authUserData}>
            <Component {...this.props} />
          </AuthUserDataContext.Provider>
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
