import React from 'react'
import { AdminModal, MaterialAdminTable, Navigation, HeaderTitle, MobileWrapper, Button } from '../../components'
import { withAuthorization } from '../../services/Session'
import styled from 'styled-components'

const SearchInput = styled.input.attrs({

})`
  width: 200px;
  height: 35px;
  font-size: 16pt;
`

const ButtonWrapper = styled.div`
  margin: 25px 0 25px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const SortBtn =styled.button`
  width: 200px;
  height: 35px;
`

const AdminWrapper = styled(MobileWrapper)`
  width: 90%;
`
class AdminView extends React.Component {

  state = {
    userData: null,
    userToDisplay: null,
    entriesToDisplay: null,
    entryPhotos: null,
    loading: false,
    entriesPerPage: 50,
    searchValue: '',
    filteredUsers: null
  }

  componentDidMount () {
    this.fetchUsers()
  }

  fetchUsers = () => {
    this.setState({ loading: true })
    this.props.firebase.getAllUserData(this.state.entriesPerPage)
      .then( response => this.filterUsers(Object.values(response)))
  }

  filterUsers = (users) => {
    let filteredUsers = users.filter( userObj => {
      return userObj.hasOwnProperty('entries')
    })
    this.setState({userData: filteredUsers}, () => this.calcLbsLost(filteredUsers))    
  }

  calcLbsLost = (users) => {
    let addedWeights = users.map( (user, index) => {
      let entries = Object.values(user.entries)
      let lbsLost = Math.round(entries[0].weight - entries[entries.length - 1].weight)
      return Object.assign(user, {lbsLost, numEntries: entries.length})
    })
    this.setState({ userData: addedWeights})
  }

  searchUsers = (event) => {
    this.setState({searchValue: event.target.value})
    // let input = this.state.searchValue
    // let results = this.state.userData.filter( user => {
    //   return user.fName.toLowerCase().includes(input.toLowerCase())
    // })
    // this.setState({ userData: results})
    // let filteredUsers = this.state.userData.filter(
    //   user => {
    //     return user.fName.indexOf(this.state.searchValue.toLowerCase()) !== -1;
    //   }
    // )
    // this.setState({filteredUsers})
    if(event.target.value === '') {
      this.setState({filteredUsers: null})
    }
    let filteredUsers = this.state.userData.filter(
      user => {
        return user.fName.toLowerCase().indexOf(event.target.value.toLowerCase())  !== -1 || user.lName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1;
      }
    )
    this.setState({filteredUsers})
  }


  
  sortUsersABC = () => {
    let users = [...this.state.userData]
    let sortedUsers = users.sort( (a, b) => {
      if(a.lName.toLowerCase() < b.lName.toLowerCase()) return -1;
      if(a.lName.toLowerCase() > b.lName.toLowerCase()) return 1;
      return 0;
    })
    this.setState({ userData: sortedUsers})
  }

  sortLbsLost = () => {
    let users = [...this.state.userData]
    let biggestLosers = users.sort( (a,b) => {
      if(a.lbsLost > b.lbsLost) return -1;
      if(a.lbsLost < b.lbsLost) return 1;
      return 0;
    })
    this.setState({userData: biggestLosers})
  }

  sortNumEntries = () => {
    let users = [...this.state.userData]
    let entrySort = users.sort( (a,b) => {
      if(a.numEntries > b.numEntries) return -1;
      if(a.numEntries < b.numEntries) return 1;
      return 0;
    })
    this.setState({userData: entrySort})
  }

  setUserToDisplay = (userToDisplay) => {
    this.state.userToDisplay ? this.setState({userToDisplay: false}) : this.setState({ userToDisplay }, () => this.setEntriesToDisplay(userToDisplay.entries))
  }

  setEntriesToDisplay = (entries) => {
    let entriesToDisplay = Object.values(entries)
    this.setState({ entriesToDisplay }, () => this.updateEntryToDisplay(0))
  }

  updateEntryToDisplay = (week) => {
    this.setState({ entryPhotos: this.state.entriesToDisplay[week]})
  }

  render () {

    const adminFragment = (
      <AdminWrapper>
        <Navigation />
        <HeaderTitle>
          Admin Dashboard
        </HeaderTitle>
        <ButtonWrapper>
          <SearchInput 
            value={this.state.searchValue}
            onChange={this.searchUsers}
            placeholder='Search by name'
          />
          <Button>
            Sort By:
          </Button>
          <Button 
              variant='contained'
              color='primary'
              onClick={()=> this.sortUsersABC()}
            >
              Last Name
            </Button>
          <Button 
            variant='contained'
            color='primary'
            onClick={()=> this.sortLbsLost()}
          >
              Most weight lost
          </Button>
          <Button 
            variant='contained'
            color='primary'
            onClick={()=> this.sortNumEntries()}
          >
                Number of Entries
            </Button>

        </ButtonWrapper>
        
        {
          this.state.userData &&

          <MaterialAdminTable 
            userData={this.state.filteredUsers ? this.state.filteredUsers : this.state.userData}
            setUserToDisplay={this.setUserToDisplay}
          />
        }

        {
          this.state.entriesToDisplay &&
          <AdminModal 
            userToDisplay={this.state.userToDisplay}
            setUserToDisplay={this.setUserToDisplay}
            entriesToDisplay={this.state.entriesToDisplay}
            entryPhotos={this.state.entryPhotos}
            updateEntryToDisplay={this.updateEntryToDisplay}
          />
        }

      </AdminWrapper>
    )
    
    return (
      <React.Fragment>
        {this.props.authUserData && this.props.authUserData.isAdmin && adminFragment}
      </React.Fragment>
    )
  }
}

const condition = authUser => !!authUser
export default withAuthorization(condition)(AdminView)
