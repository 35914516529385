import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import ProviderWithRouter from '../../services/Provider/provider'
import { withAuthentication } from '../../services/Session'

import StyledApp from './StyledApp'

const ConfiguredApp = () => (
  <BrowserRouter>
    <ProviderWithRouter>
      <StyledApp />
    </ProviderWithRouter>
  </BrowserRouter>
)

export default withAuthentication(ConfiguredApp)
