import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import WholeBodyLogo from '../../assets/whole-body-challenge-100k.png'
import { Button, Navigation, MobileWrapper } from '../../components'
import { withFirebase } from '../../services/Firebase'
import * as ROUTES from '../../constants/routes'

const HeaderImg = styled.img.attrs({
  src: WholeBodyLogo,
})`
width: 280px;
height: auto;
`

const EmailInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 310px;
  margin: 20px auto;
  padding: 0;
  border-radius: 8px;
  border-bottom: none;
`
const PasswordInputWrapper = styled(EmailInputWrapper)`
  margin: 0 auto;
`
const StyledInput = styled(TextField).attrs({
  margin:'dense',
  variant:'outlined',
})`
  border-radius: 10px;
  background-color: white;
  border-bottom: none;
`

const StyledFinePrint = styled(Typography)`
  margin: 3px 2px 20px 2px;
`
const LoginFooterWrapper = styled(Typography)`
  text-align: center;
  margin-top: 10px;
  width: 310px;
  font-size: 15px;
`
const PrivacyPolicyWrapper = styled(LoginFooterWrapper)`
  font-size: 12px;
`
const RegistrationHeader = styled.h2`
  margin-top: 15px;
  text-align:center;
  font-family: 'Raleway', sans-serif;
`

const LogInLink = styled(Link)`
  text-decoration: none;
  color: #FF6D22;
`

const RulesLink = styled.a`
  text-decoration: none;
  color: '#95989A';
`

const ValidationError = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: .25rem;
`

const ButtonWrapper = styled.div`
  position: relative;
  width: 100%;
`

const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  color: #0083BE;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const INITIAL_STATE = {
  fName: '',
  lName: '',
  email: '',
  password1: '',
  password2: '',
  isAdmin: false,
  isLoading: false,
  error: null
}

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use'

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try logging in instead or reset your password.
`

class SignUpView extends React.Component {
  state = {
    ...INITIAL_STATE
  }

  handleOnChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleOnSubmit = (event) => {
    this.setState({ isLoading: true })
    if (this.state.email === 'trevor@standardbehavior.com') {
      this.setState({ isAdmin: true})
    }
    // const { fName, lName, email, password1, isAdmin } = this.state
    this.props.firebase.doCreateUserWithEmailAndPassword(this.state.email, this.state.password1)
      .then((authUser) =>
        // authUser.user.uid === 'fJLFHsFCyxPTJ1pJ081gi8eBDLD3' ? 
        // this.setState({ isAdmin: true }) && 
        // this.props.firebase.createUserDataRecord(authUser.user.uid, email, fName, lName, isAdmin)
        // :
        this.props.firebase.createUserDataRecord(authUser.user.uid, this.state.email, this.state.fName, this.state.lName, this.state.isAdmin)
      )
      .then((result) => {
        this.setState({ ...INITIAL_STATE })
        if (result && result.typeformId) {
          // if a typeformId exists then the user data has already been collected.
          this.props.history.push(ROUTES.WEIGH_IN_INITIAL)
        } else if (result && result.email) {
          // user data returned without typeformId, redirect to the typeform
          this.props.history.push(ROUTES.SURVEY)
        } else {
          // seems that an error must have occurred, no error reporting setup, log to console?
          console.error('ERROR an unexpected error occurred', { error: result })
          throw new Error('An error occurred while registering your account. Please try again later.')
        }

      })
      .catch((error) => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }
        this.setState({ error, isLoading: false })
      })

    event.preventDefault()
  }

  render () {
    const { email, fName, lName, password1, password2, isLoading, error } = this.state
    const isInvalid =
      password1 !== password2
      || password1 === ''
      || email === ''
      || lName === ''
      || fName === ''

    return (
      <MobileWrapper>
        <Navigation />
        <div>
          <RegistrationHeader>
            REGISTRATION
          </RegistrationHeader>
          <HeaderImg />
        </div>

        { error && <ValidationError>{error.message}</ValidationError>}

        <EmailInputWrapper>
          <StyledInput
            autoFocus
            fullWidth
            id='firstName'
            label='First Name'
            name='fName'
            type='text'
            value={fName}
            onChange={this.handleOnChange}
          />
          <StyledInput
            id='lastName'
            label='Last Name'
            name="lName"
            type='text'
            value={lName}
            onChange={this.handleOnChange}
          />
          <StyledInput
            fullWidth
            variant='outlined'
            id='email'
            label='Email Address'
            name="email"
            type='email'
            value={email}
            onChange={this.handleOnChange}
          />
        </EmailInputWrapper>
        <PasswordInputWrapper>
          <StyledInput
            fullWidth
            variant='outlined'
            id='password1'
            label='Password'
            name="password1"
            type='password'
            value={password1}
            onChange={this.handleOnChange}
          />
          <StyledInput
            fullWidth
            variant='outlined'
            disableElevation
            id='password2'
            label='Confirm Password'
            name="password2"
            type='password'
            value={password2}
            onChange={this.handleOnChange}
          />
        </PasswordInputWrapper>
        <StyledFinePrint
          variant='body2'
        >
          Password must be at least 8 characters
        </StyledFinePrint>
          <ButtonWrapper>
            <Button
              rounded
              fullWidth
              size='large'
              variant='contained'
              color='secondary'
              disabled={isInvalid || isLoading}
              onClick={this.handleOnSubmit}
            >
              Create Account
            </Button>
            {isLoading && <ButtonProgress size={24} />}
          </ButtonWrapper>
        <div>
          <LoginFooterWrapper
            variant='body1'
          >
            Already Registered?<LogInLink to='login'>  Log In</LogInLink>
          </LoginFooterWrapper>
          <PrivacyPolicyWrapper>
            By signing up you accept New U Life's <RulesLink href='https://www.wholebodychallenge.com/rules-and-regulations'>Rules</RulesLink>, <RulesLink>Terms of Use</RulesLink>, and <RulesLink href='https://www.wholebodychallenge.com/privacy'>Privacy Policy</RulesLink>.
          </PrivacyPolicyWrapper>
        </div>
      </MobileWrapper>
    )
  }
}

export default withFirebase(SignUpView)
